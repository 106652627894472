import packageJson from '../../../../package.json';

export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    attributes: boolean,
    apiUrl: string;
    production: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
    packlotEnabled: boolean;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://qu8rjm0c5m.execute-api.af-south-1.amazonaws.com/Prod',
    userManagementApiUrl: 'https://3jqjube985.execute-api.af-south-1.amazonaws.com/dev',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:4d99cb4a-b953-4fba-b1c0-3e0b70130cb8',
    awsUserPoolsId: 'eu-west-1_0cQQWYpbe',
    awsUserPoolsWebClientId: '7reikjsvvq8f1ej921kgvbni4l',
    pictureManagerCloudfrontDistribution: '',
    packlotEnabled: true
};
